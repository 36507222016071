import { useState } from "react";
import bg1 from "../../assets/images/bg2.jpg";
import { useCurrentPath } from "../../utils/useCurrentPath";
import { useEffect } from "react";
export function Background({ ...props }) {
	const currentPath = useCurrentPath();
	const [backgroundColor, setBackgroundColor] = useState(
		"--primary-accent-color"
	);
	useEffect(() => {
		if (currentPath === "/contact") {
			setBackgroundColor("--contact-background-color");
		} else {
			setBackgroundColor("--primary-accent-color");
		}
	}, [currentPath]);
	return (
		<div
			style={{
				zIndex: -1,
				position: "fixed",
				height: "100vh",
				width: "100vw",
			}}>
			{currentPath === "/" && (
				<img
					src={bg1}
					style={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
						// filter: "blur(0.7rem)",
						// filter: "blur(1.4rem)",
						filter: "blur(1.2rem)",
						// transform: "scaleX(-1)",
						scale: "1.2",
					}}
					alt="Background"
				/>
			)}
			{currentPath !== "/" && (
				<svg id="visual" width="100%" height="100%" version="1.1">
					<rect
						x="0"
						y="0"
						width="100%"
						height="100%"
						fill={`var(${backgroundColor})`}></rect>
				</svg>
			)}
		</div>
	);
}
