import { BASE_URL } from "../data/constants";

export default function sendMail(
	details,
	setLoading,
	setSuccess,
	setError,
	formReset
) {
	const handleFailure = () => {
		setSuccess(false);
		setError(true);
		setLoading(false);
		window.open(
			`mailto:sreprinto2000@gmail.com?subject=Requesting_Details_From_Website&body=${JSON.stringify(
				details
			)}`
		);
	};
	setLoading(true);
	fetch(`${BASE_URL}/sendMail`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(details),
	})
		.then((response) => {
			setLoading(false);
			if (response.status === 200) {
				setError(false);
				setSuccess(true);
			} else {
				handleFailure();
			}
		})
		.catch((err) => {
			handleFailure();
		})
		.finally(() => {
			formReset();
			setLoading(false);
		});
}
