import { Col, Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import "./home.css";
import ImageGallery from "react-image-gallery";
import { Services } from "../../components/servicesSection/ServicesSection";
import { importAll } from "../../utils/imageUtil";
import { CTA } from "../../components/cta/CTA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const assetImages = importAll(
	require.context(
		"../../assets/images/carousel",
		false,
		/\.(png|jpe?g|svg|webp)$/
	)
);
const images = [
	{
		original: assetImages["printer3.webp"],
		originalAlt: "Printer Image 3",
	},
	{
		original: assetImages["printer2.jpeg"],
		originalAlt: "Printer Image 2",
	},
	{
		original: assetImages["printer1.webp"],
		originalAlt: "Printer Image 1",
	},
];

export function Home({ title, ...props }) {
	useEffect(() => {
		if (title) {
			document.title = title;
		}
	}, [title]);
	return (
		<div className="home-wrapper">
			<Row>
				<Col
					sm={0}
					xs={0}
					className="hide-when-small"
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<h1
						style={{ margin: 0 }}
						data-aos="fade-down"
						className="hide-when-small home-brand">
						SRE PRINTOGRAPH
					</h1>
				</Col>

				<Col
					className="justify-content-center"
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<div
						data-aos="fade-left"
						data-aos-duration="1200"
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							flexFlow: "row-reverse",
							alignItems: "center",
							padding: "5px",
						}}>
						<FontAwesomeIcon
							icon={faPhone}
							style={{ fontSize: "1.2rem", color: "white" }}
						/>
						<a href="tel:+919443226751">
							<p className="montserrat contact">+91 9443226751</p>
						</a>
					</div>
					<div
						data-aos="fade-left"
						data-aos-duration="1200"
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							flexFlow: "row-reverse",
							alignItems: "center",
							padding: "5px",
						}}>
						<FontAwesomeIcon
							icon={faEnvelope}
							style={{ fontSize: "1.2rem", color: "white" }}
						/>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "end",
							}}>
							<a href="mailto:sreprinto2000@gmail.com">
								<p className="montserrat contact">
									sreprinto2000@gmail.com
								</p>
							</a>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md="6">
					<p
						data-aos="fade-right"
						data-aos-duration="1000"
						className="home-header">
						Printing Made Easy with Us
					</p>
					<p
						data-aos="fade-right"
						data-aos-duration="1200"
						className="home-subtext">
						We, Sre Printograph, have been providing high-quality
						offset printing services in and around Tamil Nadu since
						1997. We have been operating for the past 25 years in
						Sivakasi under the sole ownership of Mr. Sivakumar D. In
						the event of the Silver Jubilee, we are expanding our
						web presence so people like you can reach us easier for
						Dazzling Printing Works.
					</p>
					<div
						data-aos="fade-right"
						data-aos-duration="1200"
						className="bottom-margin-when-small">
						<CTA />
					</div>
				</Col>
				<Col md="6">
					<div data-aos="fade-left" data-aos-duration="600">
						<ImageGallery
							autoPlay
							slideDuration="3"
							showNav={false}
							showBullets
							disableSwipe={false}
							showPlayButton={false}
							showFullscreenButton={false}
							items={images}
							showThumbnails={false}
						/>
					</div>
				</Col>
			</Row>
			<Services />
			<div style={{ height: "100px" }}></div>
		</div>
	);
}
