import { useSearchParams } from "react-router-dom";
import "./products.css";
import { useEffect } from "react";
import { productsContent } from "../../data/constants";
import { Col, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";

export function Products({ title, ...props }) {
	const [searchParams] = useSearchParams();
	const handleClickScroll = (id) => {
		const element = document.getElementById(id);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
		} else {
			window.scrollTo(0, 0);
		}
	};
	useEffect(() => {
		if (title) {
			document.title = title;
		}
	}, [title]);
	useEffect(() => {
		const sectionId = searchParams.get("section");
		handleClickScroll(sectionId);
	}, [searchParams]);
	return (
		<div className="products-wrapper">
			<p
				data-aos-delay="500"
				data-aos="fade-down"
				data-aos-duration="1500"
				className="product-page-title">
				Our Products
			</p>
			<p
				data-aos-delay="900"
				data-aos="fade-down"
				data-aos-duration="1500"
				className="products-description">
				First impressions are very important, and your packaging is
				often a consumer's first introduction to the product. So, we Sre
				Printograph the Prima facie for Quality printing, durability,
				flawless design at reasonable prices.
			</p>
			<br />

			{productsContent.map((value, index) => {
				return (
					<div key={index} className="product-item-row">
						<Row
							data-aos-delay="900"
							data-aos="fade-right"
							data-aos-duration="1500">
							<Col>
								<p id={value.title} className="product-title">
									{value.title}
								</p>
								<div className="line"></div>
								<br />
							</Col>
						</Row>
						<Row>
							<Col
								data-aos-delay="900"
								data-aos="fade-right"
								data-aos-duration="1500"
								md={6}>
								{value.body.map((content, index) => {
									return (
										<p
											className="product-content"
											key={index}>
											{content}
										</p>
									);
								})}
							</Col>
							<Col
								data-aos-delay="900"
								data-aos="fade-left"
								data-aos-duration="1500"
								md={6}>
								<ImageGallery
									autoPlay
									slideDuration="3"
									showNav={false}
									showThumbnails={false}
									showBullets
									disableSwipe={false}
									showPlayButton={false}
									showFullscreenButton={false}
									items={value.imgs}
									lazyLoad
								/>
							</Col>
						</Row>
					</div>
				);
			})}
		</div>
	);
}
