import Hamburger from "hamburger-react";
import { Col, Dropdown } from "react-bootstrap";
import "./navbar.css";
import logo from "../../assets/images/logo.png";
import { products, services } from "../../data/constants";
import { CTA } from "../cta/CTA";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentPath } from "../../utils/useCurrentPath";
import React from "react";

export function NavigationBar({
	isSideDrawerOpen,
	setShowSideDrawer,
	...props
}) {
	const currentPath = useCurrentPath();
	return (
		<div className="cust-nav svg-bg justify-content-between align-items-start py-3">
			<Col
				md={"1"}
				sm={"3"}
				xs={"3"}
				style={{ marginLeft: "40px" }}
				className="brand unselectable pointer">
				<Link to="/">
					<div className="brand-container">
						<img
							src={logo}
							className="brand-logo"
							alt="Sre Printograph Brand Logo"
						/>
						<span>SRE PRINTOGRAPH</span>
					</div>
				</Link>
			</Col>
			<Col sm="3" xs="3" className="hide-when-big">
				<div
					style={{
						width: "100%",
					}}></div>
			</Col>
			<Col md={"1"} sm={"3"} xs={"3"} className="align-items-center">
				<span className="hide-when-big">
					<Hamburger
						toggled={isSideDrawerOpen}
						toggle={isSideDrawerOpen}
						rounded
						onToggle={(toggled) => {
							setShowSideDrawer(toggled);
						}}
					/>
				</span>
			</Col>
			<Col md={"1"} lg={"1"}></Col>
			<Col md={"1"}>
				<NaviItem selected={currentPath === "/"} name={"Home"} />
			</Col>
			<Col md={"1"}>
				<NaviDropdownItem
					redirect={"products"}
					name={"Products"}
					selected={currentPath === "/products"}
					list={products}
				/>
			</Col>
			<Col md={"1"}>
				<NaviDropdownItem
					redirect={"services"}
					name={"Services"}
					selected={currentPath === "/services"}
					list={services}
				/>
			</Col>
			<Col md={"1"} className="justify-content-start">
				<NaviItem
					selected={currentPath === "/contact"}
					name={"Contact"}
				/>
			</Col>
			<Col md={"2"}>
				{currentPath !== "/contact" && (
					<CTA isOnNavbar={true} hideWhenSmall />
				)}
			</Col>
		</div>
	);
}

function NaviItem({ name, selected, ...props }) {
	return (
		<Link to={name === "Home" ? "/" : name}>
			<div className={"nav-item hide-when-small unselectable"}>
				<span
					className={
						"linkk hover-2 nav-item-link " +
						(selected ? "selected-nav" : "")
					}>
					{name}
				</span>
			</div>
		</Link>
	);
}
function NaviDropdownItem({ redirect, name, list, selected, ...props }) {
	const navigate = useNavigate();
	return (
		<div className={"nav-item hide-when-small unselectable"}>
			<Dropdown>
				<Dropdown.Toggle
					as={CustomToggle}
					ref={(ref) => {
						if (selected && ref) {
							ref.classList.add("selected-nav");
						} else if (!selected && ref) {
							ref.classList.remove("selected-nav");
						}
					}}
					id="dropdown-custom-components">
					{name}
				</Dropdown.Toggle>
				<Dropdown.Menu as={CustomMenu} style={{ height: "auto" }}>
					{list.map((value, index, list) => {
						return (
							<Dropdown.Item
								as={CustomDropDownItem}
								key={index}
								eventKey={index}
								onClick={() => {
									navigate(redirect + "?section=" + value, {
										replace: false,
									});
								}}>
								{/* <Link to={redirect}> */}
								{value}
								{/* </Link> */}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
	return (
		<span
			ref={ref}
			className="linkk hover-2 nav-item-link"
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}>
			{children}
		</span>
	);
});

const CustomDropDownItem = React.forwardRef(({ children, onClick }, ref) => (
	<span
		ref={ref}
		className="dropdown-item"
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}>
		{children}
	</span>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
	({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}>
				<ul className="list-unstyled">{children}</ul>
			</div>
		);
	}
);
