import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Base } from "./views/base";
import { Home } from "./views/home/home";
import { Services } from "./views/services/Services";
import { Contact } from "./views/contact/contact";
import { Products } from "./views/products/products";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
function App() {
	useEffect(() => {
		Aos.init({
			// disable: "phone",
			once: true,
			duration: 1000,
			// delay: 500,
		});
	}, []);
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Base />}>
						<Route
							path="/"
							element={<Home title="Sre Printograph Sivakasi" />}
						/>
						<Route
							path="/services"
							element={
								<Services title="Sre Printograph Sivakasi - Services" />
							}
						/>
						<Route
							path="/contact"
							element={
								<Contact title="Sre Printograph Sivakasi - Contact Us" />
							}
						/>
						<Route
							path="/products"
							element={
								<Products title="Sre Printograph Sivakasi - Products" />
							}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
