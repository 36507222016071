import { importAll } from "../utils/imageUtil";
// eslint-disable-next-line
const API_PROD_BASE_URL =
	"https://us-central1-sre-printograph.cloudfunctions.net/app";
// eslint-disable-next-line
const API_DEV_BASE_URL = "http://127.0.0.1:3001";

export const BASE_URL = API_PROD_BASE_URL;
const productImages = importAll(
	require.context(
		"../assets/images/products",
		false,
		/\.(png|jpe?g|svg|webp)$/
	)
);
const serviceImages = importAll(
	require.context(
		"../assets/images/services",
		false,
		/\.(png|jpe?g|svg|webp|jfif)$/
	)
);

export const products = [
	"Incense Sticks Box",
	"Dhoop Cup",
	"Garment Box",
	"Luxury Box",
	"Booklets and Catalogues",
	"Commercial Letterpads",
	"Labels and Stickers",
];

export const services = [
	"Offset Printing",
	"Protective Coating",
	"Silkscreen Printing",
	"Die Cutting",
	"Pasting and Lamination",
];

export const productsContent = [
	{
		title: "Incense Sticks Box",
		body: [
			"We provide top-quality and long-lasting Incense Stick Boxes in the market. We also customize the incense stick boxes with eye-catching designs to indicate the variety of sticks, HD printing to highlight the scent, an accurate size to pack sticks, and catchy laminations to increase brand recognition.",
		],
		imgs: [
			{
				original: productImages["incense_stick1.jpg"],
				originalAlt: "Incense Stick",
			},
			{
				original: productImages["incense_stick2.jpg"],
				originalAlt: "Incense Stick",
			},
			{
				original: productImages["incense_stick4.jpg"],
				originalAlt: "Incense Stick",
			},
			{
				original: productImages["incense_stick5.jpg"],
				originalAlt: "Incense Stick",
			},
			{
				original: productImages["incense_stick6.jpg"],
				originalAlt: "Incense Stick",
			},
		],
	},
	{
		title: "Dhoop Cup",
		body: [
			"We are engaged in delivering an elegant range of Dhoop Cup Boxes which are examined on various stages for superior quality. We also offer Customized (single piece Box or Dozen Box) sizes and designs of Dhoop cup Boxes to clients at a competitive cost.",
		],
		imgs: [
			{
				original: productImages["dhoop_box1.jpg"],
				originalAlt: "Dhoop Box",
			},
			{
				original: productImages["dhoop_box2.jpg"],
				originalAlt: "Dhoop Box",
			},
			{
				original: productImages["dhoop_box3.jpg"],
				originalAlt: "Dhoop Box",
			},
			{
				original: productImages["dhoop_box4.jpg"],
				originalAlt: "Dhoop Box",
			},
		],
	},
	{
		title: "Garment Box",
		body: [
			"We are known as a prominent manufacturer and supplier of a range of Garment Packing Boxes. We make boxes using high-quality raw materials to ensure durability and a longer functional life. Further, our boxes are checked on several quality parameters to confirm market standards.",
			"We use IVORY Paper Boards (virgin board) for pure shades and bright designs.",
		],
		imgs: [
			{
				original: productImages["garment_box1.jpg"],
				originalAlt: "Garment Box",
			},
			{
				original: productImages["garment_box2.jpg"],
				originalAlt: "Garment Box",
			},
			{
				original: productImages["garment_box3.jpg"],
				originalAlt: "Garment Box",
			},
			{
				original: productImages["garment_box4.jpg"],
				originalAlt: "Garment Box",
			},
			{
				original: productImages["garment_box5.jpg"],
				originalAlt: "Garment Box",
			},
		],
	},
	{
		title: "Luxury Box",
		body: [
			"We are known for making luxury boxes with a variety of graphics and colors. We offer vibrant and eye-catching luxury boxes with more elaborate and extravagant designs. With the process of die-cutting from our professionals, you can add different shapes to make your customer glimpse your product and build a strong brand image.",
			"We use high-quality materials like Cardboard, E-flute, Kraft with Glossy and Matte finish to give extra support and a luxurious look to your product.",
		],
		imgs: [
			{
				original: productImages["luxury1.jpg"],
				originalAlt: "Luxury Box",
			},
			{
				original: productImages["luxury2.jpg"],
				originalAlt: "Luxury Box",
			},
			{
				original: productImages["luxury3.jpg"],
				originalAlt: "Luxury Box",
			},
			{
				original: productImages["luxury4.jpg"],
				originalAlt: "Luxury Box",
			},
		],
	},
	{
		title: "Booklets and Catalogues",
		body: [
			"Custom booklet printing is a great way to showcase your products or services in vibrant, full-color multi-page formats. Standard booklets and catalogs are printed on high-quality paper stock and include wire-o or stapled binding options. Standard booklets are a great way for brands, companies, and events to deliver handy information to customers in a vibrant, multi-page format.",
			"Perfect bind booklets are ideal for larger projects that need an extra polish. Similar to a book binding, we give a flat spine that provides a professional, high-end look to your booklet or catalog. 100% recycled- uncoated, and Premium Matte pages are available, along with vibrant full-color printing.",
		],
		imgs: [
			{
				original: productImages["catalog1.jpg"],
				originalAlt: "Catalog",
			},
		],
	},
	{
		title: "Commercial Letterpads",
		body: [
			"We provide customers the flexibility to get the design on the letter pad according to their preferences.",
			"We offer a full range of letter pads printed on superior-quality uncoated paper stock. We have notable expertise in Letter Pads printing. Various range of letter pad printing options is available at an affordable price.",
		],
		imgs: [
			{
				original: productImages["letter1.jpg"],
				originalAlt: "Letter Pad",
			},
			{
				original: productImages["letter2.jpg"],
				originalAlt: "Letter Pad",
			},
			{
				original: productImages["letter3.jpg"],
				originalAlt: "Letter Pad",
			},
			{
				original: productImages["letter4.png"],
				originalAlt: "Letter Pad",
			},
		],
	},
	{
		title: "Labels and Stickers",
		body: [
			"We provide custom labels and sticker printing services done by our experts. You can choose from a vast assortment of superior materials and finishes to get the exact look you want. We print custom labels on sheets and rolls for both hand and machine applications.",
			"We provide film-label materials to resist oils and water, abrasions, tearing, and fading. We use superior quality bold metallics and holographic labels to make your brand stand out. To make your product labels pop, we use white ink, Silver metallic paper, chrome, and squeezable films.",
		],
		imgs: [
			{
				original: productImages["label1.jpg"],
				originalAlt: "Label",
			},
			{
				original: productImages["label2.jpg"],
				originalAlt: "Label",
			},
			{
				original: productImages["label3.jpg"],
				originalAlt: "Label",
			},
			{
				original: productImages["sticker1.jpg"],
				originalAlt: "Label",
			},
			{
				original: productImages["sticker2.jpg"],
				originalAlt: "Label",
			},
		],
	},
];

export const servicesContent = [
	{
		title: "Offset Printing",
		body: "We are providing premium multi-color printing services for decades to our customers in and around Tamil Nadu. Sre Printograph has a tie-up with multi-color offset printers with advanced technology in digital printing facilities. We are experienced in all types of printing services and offer high-quality Printing & Designing services at a reasonable cost with estimated delivery in time. Offset printing can be configured to produce high-volume, press-finished magazines or catalogs.",
		imgs: [
			{ img: serviceImages["web-offset.jpeg"], alt: "Offset Printing 1" },
			{ img: serviceImages["web-offset1.jpg"], alt: "Offset Printing 2" },
		],
		hasBullets: false,
		bullets: [],
	},
	{
		title: "Protective Coating",
		body: "We provide protective coating services for large and small-sized printing quotations. Customers having limited orders may opt for sheet-fed printing. Some stuff made via sheet-fed printing is Business Cards, Letterhead Pads, Brochures, Flyers, and Business Forms. We provide a variety of Coatings such as :",
		imgs: [
			{
				img: serviceImages["sheet-fed2.jpg"],
				alt: "sheet fed printing 2",
			},
			{
				img: serviceImages["sheet-fed3.jfif"],
				alt: "sheet fed printing 3",
			},
		],
		hasBullets: true,
		bullets: [
			"Varnish - Gloss, Dull, and Matte",
			"Aqueous - Overall Gloss, Dull, Matte and Soft-Touch",
			"UV virtue - Spot and Overall Gloss, Matte, Dull, Textured and Glitter",
		],
	},
	{
		title: "Silkscreen Printing",
		body: "We are offering Silkscreen printing services, which is the most popular and traditional printing technique used for various products. We offer this service to customers who need elegant patterned printing on posters, fabrics, packaging, tote bags, and ceramics with Vibrant results, Texturized effects, and long-lasting prints.",
		imgs: [
			{
				img: serviceImages["silk-screen1.jpg"],
				alt: "Silk Screen Printing 1",
			},
		],
		hasBullets: false,
		bullets: [],
	},
	{
		title: "Die Cutting",
		body: "We are providing advanced Die Cutting Services with our highly equipped professionals working alongside our customers. These products are made from solid rubber, urethane, compressed fiber, plastics, and much more. Our quality standards are among the highest in the die-cutting industry.",
		imgs: [{ img: serviceImages["die-cut.png"], alt: "Die Cutting" }],
		hasBullets: false,
		bullets: [],
	},
	{
		title: "Pasting and Lamination",
		body: "We provide high-quality automatic pasting and the latest lamination services for durable protection of the products that keep moisture, oil, and dirt away from the edge of the printed piece. The benefit of lamination is that it makes the colors on the printed piece more brilliant.",
		imgs: [{ img: serviceImages["lamination.jpg"], alt: "Lamination" }],
		hasBullets: false,
		bullets: [],
	},
];
