import { useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./SlideDrawer.css";
import logo from "../../assets/images/logo.png";
import { useCurrentPath } from "../../utils/useCurrentPath";
export default function SlideDrawer({ showDrawer, setShowDrawer, ...props }) {
	//Accepted Values: / | products | services | contact
	const [linkState, setLinkState] = useState(useCurrentPath());
	var drawerClasses = "side-drawer";
	const navigate = useNavigate();
	if (showDrawer === true) {
		drawerClasses = "side-drawer open";
	}
	const handleNavigation = (link) => {
		setLinkState(link);
		setShowDrawer(false);
		navigate(link);
	};
	return (
		<div className={drawerClasses}>
			<Row>
				<Col>
					<div className="brand unselectable side-drawer-brand">
						<img
							src={logo}
							style={{ height: "20vw" }}
							alt="Sre Printograph Brand Logo"
						/>
						<div className="brand-name">SRE PRINTOGRAPH</div>
					</div>
					<ListGroup variant="flush" className="unselectable pointer">
						<ListGroup.Item
							className="side-drawer-nav-item"
							active={linkState === "/"}
							onClick={() => {
								handleNavigation("/");
							}}>
							Home
						</ListGroup.Item>
						<ListGroup.Item
							className="side-drawer-nav-item"
							active={linkState === "/products"}
							onClick={() => {
								handleNavigation("/products");
							}}>
							Products
						</ListGroup.Item>
						<ListGroup.Item
							className="side-drawer-nav-item"
							active={linkState === "/services"}
							onClick={() => {
								handleNavigation("/services");
							}}>
							Services
						</ListGroup.Item>
						<ListGroup.Item
							className="side-drawer-nav-item"
							active={linkState === "/contact"}
							onClick={() => {
								handleNavigation("contact");
							}}>
							Contact
						</ListGroup.Item>
						{/* <ListGroup.Item>
							className="side-drawer-nav-item"
							<div className="cta unselectable">
								Request a Quote
							</div>
						</ListGroup.Item> */}
					</ListGroup>
				</Col>
			</Row>
		</div>
	);
}
