import { useForm } from "react-hook-form";
import { CTA } from "../../components/cta/CTA";
import "./quoteForm.css";
import { useState } from "react";
import Lottie from "lottie-react";
import successLottie from "../../assets/lottie_files/success.json";
import sendMail from "../../utils/mail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export function QuoteForm({ ...props }) {
	const [formRef, setFormRef] = useState(null);
	const [showSuccessDialog, setShowSuccessDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	return (
		<>
			{isLoading && (
				<div className="center-wrapper">
					<div className="lds-dual-ring"></div>
					<p className="loader-text">Loading...</p>
				</div>
			)}
			{isError && (
				<div className="center-wrapper">
					<FontAwesomeIcon
						icon={faWarning}
						style={{
							color: "rgb(209,0,0)",
							fontSize: "5rem",
						}}
					/>
					<p className="error-mail">OOPS! Error Occured</p>
					<CTA
						title="Retry"
						onClick={() => {
							setIsError(false);
						}}
					/>
					<div
						style={{
							height: "2rem",
						}}></div>
				</div>
			)}
			{showSuccessDialog && (
				<div className="success-content-wrapper">
					<Lottie
						style={{
							height: "300px",
						}}
						animationData={successLottie}
						loop={true}
					/>
					<p className="mail-success">
						<span style={{ textAlign: "center", display: "block" }}>
							We got your details.
						</span>
						We will reach out to you soon! Please reach out to{" "}
						<a
							href="mailto:sreprinto2000@gmail.com"
							className="form-email">
							sreprinto2000@gmail.com
						</a>{" "}
						for more details.
					</p>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<CTA
							title="Okay !"
							onClick={() => {
								setIsError(false);
								setShowSuccessDialog(false);
								setIsLoading(false);
							}}
						/>
					</div>
				</div>
			)}
			{!showSuccessDialog && !isError && !isLoading && (
				<div
					data-aos="fade-left"
					data-aos-duration="1000"
					data-aos-delay="750"
					className="formm-content-wrapper">
					<p className="form-heading">How can we help you?</p>
					<p className="form-subheading">
						You can reach us anytime via &nbsp;
						<a
							className="heading-email"
							href="mailto:sreprinto2000@gmail.com">
							sreprinto2000@gmail.com
						</a>
					</p>
					<form
						onSubmit={handleSubmit((fieldValues) => {
							sendMail(
								fieldValues,
								setIsLoading,
								setShowSuccessDialog,
								setIsError,
								reset
							);
						})}
						ref={(ref) => {
							if (ref) {
								setFormRef(ref);
							}
						}}>
						<FieldName name={"Name"} />
						<input
							type="text"
							className="text-field"
							placeholder="Your Name"
							{...register("name", { required: true })}
						/>
						{errors.name && (
							<span className="error-text">Name is Required</span>
						)}
						<FieldName name={"Email"} />
						<input
							type="email"
							className="text-field"
							placeholder="you@company.com"
							{...register("email", {
								required: true,
								pattern:
									/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
							})}
						/>
						{errors.email && (
							<span className="error-text">
								{errors.email.type === "pattern"
									? "Enter valid Email"
									: "Email is Required"}
							</span>
						)}
						<FieldName name={"Phone Number"} />
						<input
							type="number"
							className="text-field"
							placeholder=""
							{...register("mobile", {
								required: true,
								minLength: 10,
								maxLength: 10,
							})}
						/>
						{errors.mobile && (
							<span className="error-text">
								{errors.mobile.type === "required"
									? "Mobile Number is Required"
									: "Enter Valid Mobile Number"}
							</span>
						)}
						<FieldName name={"Place"} />
						<input
							type="text"
							className="text-field"
							placeholder="City, State"
							{...register("place", { required: true })}
						/>
						{errors.place && (
							<span className="error-text">
								Place is Required
							</span>
						)}
						<FieldName name={"How can we help?"} />
						<textarea
							className="text-field text-area"
							rows={4}
							placeholder={"Required Products and Quantity"}
							{...register("details", { required: true })}
						/>
						{errors.details && (
							<span className="error-text">
								Please enter your query
							</span>
						)}

						<p className="disclaimer">
							By clicking{" "}
							<strong
								style={{
									fontWeight: "600",
								}}>
								'Request a Quote'
							</strong>{" "}
							you are agreeing to share the above details with us.
						</p>
						<CTA
							onClick={() => {
								if (formRef) {
									formRef.dispatchEvent(
										new Event("submit", {
											bubbles: false,
											cancelable: true,
										})
									);
								}
							}}
						/>
					</form>
				</div>
			)}
		</>
	);
}

function FieldName({ name, ...props }) {
	return <div className="field-name">{name}</div>;
}
