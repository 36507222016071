import "./Services.css";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { servicesContent } from "../../data/constants";
import ReactImageGallery from "react-image-gallery";

export function Services({ title, ...props }) {
	const [searchParams] = useSearchParams();
	const handleClickScroll = (id) => {
		const element = document.getElementById(id);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
		} else {
			window.scrollTo(0, 0);
		}
	};
	useEffect(() => {
		if (title) {
			document.title = title;
		}
	}, [title]);
	useEffect(() => {
		const sectionId = searchParams.get("section");
		handleClickScroll(sectionId);
	}, [searchParams]);
	return (
		<div className="service-wrapper">
			<Row>
				<Col>
					<p
						data-aos-delay="500"
						data-aos="fade-down"
						data-aos-duration="1500"
						className="service-title">
						Our Services
					</p>
					{servicesContent.map((service, index) => {
						return (
							<ServiceItem
								key={index}
								title={service.title}
								body={service.body}
								imgs={service.imgs}
								index={index}
								hasBullets={service.hasBullets}
								bullets={service.bullets}
							/>
						);
					})}
				</Col>
			</Row>
		</div>
	);
}

function ServiceItem({ title, body, imgs, index, hasBullets, bullets }) {
	const isContentAfterImage = index % 2 === 0;
	return (
		<Row className={"service-item "}>
			<Col
				data-aos-delay="500"
				data-aos="fade-down"
				data-aos-duration="1500"
				className="justify-content-center">
				<p id={title} className="content-title">
					{title}
				</p>
				<div className="line"></div>
			</Col>
			<Row>
				<Col className="justify-content-md-center">
					{/* {imgs.length === 1 && (
					<img src={imgs} className="service-image" alt="" />
				)} */}
					<Row
						className={
							isContentAfterImage
								? "content-img-row"
								: "img-content-row"
						}>
						<Col
							data-aos-duration="1500"
							data-aos-delay="500"
							data-aos={
								!isContentAfterImage
									? "fade-left"
									: "fade-right"
							}
							md={6}>
							<p className="content">{body}</p>
							{hasBullets && (
								<ul>
									{bullets.map((item, index) => {
										return (
											<li
												key={index}
												className="services-list-item">
												<p>{item}</p>
											</li>
										);
									})}
								</ul>
							)}
						</Col>
						<Col
							style={{ marginTop: "2rem" }}
							data-aos-duration="1500"
							data-aos-delay="500"
							data-aos={
								isContentAfterImage ? "fade-left" : "fade-right"
							}
							md={6}>
							<ReactImageGallery
								autoPlay
								slideDuration="3"
								showNav={false}
								showThumbnails={false}
								showBullets={imgs.length > 1}
								disableSwipe={false}
								showPlayButton={false}
								showFullscreenButton={false}
								items={imgs.map((imgObj, index) => {
									return {
										original: imgObj.img,
										originalAlt: imgObj.alt,
									};
								})}
								lazyLoad
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Row>
	);
}
