import { Col, Row } from "react-bootstrap";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEnvelope,
	faLocationDot,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import mapImg from "../../assets/images/map.jpg";
import { QuoteForm } from "./quoteForm";
import { useEffect } from "react";

export function Contact({ title, ...props }) {
	useEffect(() => {
		if (title) {
			document.title = title;
		}
	}, [title]);
	return (
		<div className="contact-page-wrapper">
			<Row>
				<Col
					data-aos="fade-right"
					data-aos-duration="1000"
					md={4}
					sm={12}
					xs={12}
					className="contact-wrapper">
					<div
						data-aos="fade-right"
						data-aos-duration="1000"
						data-aos-delay="750"
						className="contact-content-wrapper">
						<p className="contact-heading">Get in Touch</p>
						<span className="contact-subheading">
							We'd love to hear from you. We're always here for
							your queries.
						</span>
						<Chat />
						<Office />
						<Phone />
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
							}}>
							<a
								href="https://wa.me/+919791566751"
								rel="noreferrer"
								target={"_blank"}
								className="whatsapp-link">
								<FontAwesomeIcon
									icon={faWhatsapp}
									style={{
										fontSize: "3rem",
										marginTop: "1rem",
									}}
								/>
							</a>
						</div>
					</div>
				</Col>
				<Col
					data-aos="fade-left"
					data-aos-duration="1000"
					md={8}
					sm={12}
					xs={12}
					className="form-wrapper">
					<QuoteForm />
				</Col>
			</Row>
		</div>
	);
}

function Chat() {
	return (
		<div className="item-wrapper">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "start",
				}}>
				<FontAwesomeIcon
					icon={faEnvelope}
					style={{
						fontSize: "1.2rem",
						marginTop: "4px",
					}}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginLeft: "1rem",
					}}>
					<span className="item-heading">Chat to Us</span>
					<span className="item-subheading">
						We're here to help you anytime.
					</span>
					<a href="mailto:sreprinto2000@gmail.com">
						<p className="email">sreprinto2000@gmail.com</p>
					</a>
					<a href="mailto:madhavasraj@gmail.com">
						<p className="email">madhavasraj@gmail.com</p>
					</a>
				</div>
			</div>
		</div>
	);
}
function Office() {
	return (
		<div className="item-wrapper">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "start",
				}}>
				<FontAwesomeIcon
					icon={faLocationDot}
					style={{
						fontSize: "1.2rem",
						marginTop: "4px",
					}}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginLeft: "1rem",
					}}>
					<span className="item-heading">Office</span>
					<span className="item-subheading">
						Come say hello to our Office.
					</span>
					<span
						style={{
							fontWeight: 500,
							fontSize: "0.9rem",
							marginTop: "1rem",
						}}
						className="item-subheading">
						1 / 202 / 3-E, <br /> Sakthi Nagar, Sivakasi West,
						<br /> Tamil Nadu, India - 626 124.
					</span>
				</div>
			</div>
			<a
				href="https://maps.google.com/maps?q=9.475263119740854,77.76135379015415"
				rel="noreferrer"
				target={"_blank"}>
				<img
					src={mapImg}
					style={{
						width: "100%",
						marginTop: "1.5rem",
					}}
					alt="Sre Printograph Location in map"
				/>
			</a>
		</div>
	);
}
function Phone() {
	return (
		<div className="item-wrapper">
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "start",
				}}>
				<FontAwesomeIcon
					icon={faPhone}
					style={{
						fontSize: "1.2rem",
						marginTop: "4px",
					}}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						marginLeft: "1rem",
					}}>
					<span className="item-heading">Phone</span>
					<span className="item-subheading">
						Mon-Sat from 9am to 9pm
					</span>
					<a href="tel:+919443226751">
						<p className="email">+91 9443226751</p>
					</a>
					<a href="tel:+919791566751">
						<p className="email">+91 9791566751</p>
					</a>
				</div>
			</div>
		</div>
	);
}
