import { Col, Row } from "react-bootstrap";
import { importAll } from "../../utils/imageUtil";
import "./ServicesSection.css";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const serviceImages = importAll(
	require.context(
		"../../assets/images/service-icons",
		false,
		/\.(png|jpe?g|svg|webp)$/
	)
);
export function Services({ ...props }) {
	return (
		<div
			style={{
				marginTop: "50px",
			}}>
			<Row className="justify-content-center">
				<Col
					md={6}
					lg={4}
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<ServiceCard
						animate="flip-left"
						imgSrc={serviceImages["sample.png"]}
						title={"Samples"}
						subtitle={"Experience Yourself"}
						content={
							"Get a sample of your artwork beforehand and feel the quality by yourself."
						}
						linkText={"Our Services"}
						linkUrl={"/services"}
					/>
				</Col>
				<Col
					md={6}
					lg={4}
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<ServiceCard
						animate="flip-up"
						imgSrc={serviceImages["print.png"]}
						title={"Quality Printing"}
						subtitle={"in Marketplace"}
						content={
							"We take no chances when it comes to quality. What you see is what you will get."
						}
						linkText={"Our Products"}
						linkUrl={"/products"}
					/>
				</Col>
				<Col
					md={6}
					lg={4}
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<ServiceCard
						animate="flip-right"
						imgSrc={serviceImages["ship.png"]}
						title={"Best Delivery"}
						subtitle={"Fast and Safe"}
						content={
							"We put our utmost care into packaging your product. It will get to you with no hassle."
						}
						linkText={"Contact Us"}
						linkUrl={"/contact"}
					/>
				</Col>
			</Row>
		</div>
	);
}

function ServiceCard({
	imgSrc,
	title,
	subtitle,
	content,
	linkText,
	animate = "flip-up",
	delay = "0",
	linkUrl = "/",
	...props
}) {
	const navigate = useNavigate();
	return (
		<div className="service-card" data-aos={animate}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
				}}>
				<img className="service-img" src={imgSrc} alt={title} />
			</div>
			<div>
				<p className="service-section-title">{title}</p>
				<p className="service-subtitle">{subtitle}</p>
			</div>
			<div>
				<p className="service-content">{content}</p>
				<div>
					<div
						onClick={() => {
							navigate(linkUrl);
						}}
						className="service-link">
						{linkText} ›
					</div>
				</div>
			</div>
		</div>
	);
}
