import { useNavigate } from "react-router-dom";

export function CTA({
	hideWhenSmall = false,
	isOnNavbar = false,
	onClick,
	title = "Request a Quote",
	...props
}) {
	const navigate = useNavigate();
	return (
		<button
			onClick={() => {
				if (onClick) {
					onClick();
				} else {
					navigate("/contact?requestQuote=true");
				}
			}}
			className={
				"cta unselectable primary shimmerable " +
				(hideWhenSmall ? "hide-when-small " : "") +
				(isOnNavbar ? "navbarCta " : "")
			}>
			{title}
		</button>
	);
}
