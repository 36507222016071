import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import "./base.css";
import { Container } from "react-bootstrap";
import { NavigationBar } from "../components/navbar/navbar";
import SlideDrawer from "../components/slideDrawer/SlideDrawer";
import Backdrop from "../components/backdrop/Backdrop";
import { useEffect, useState } from "react";
import { Background } from "../components/background/Background";
import { useCurrentPath } from "../utils/useCurrentPath";
import logo from "../assets/images/logo-white.png";
import { BASE_URL } from "../data/constants";
export function Base({ ...props }) {
	const [showDrawer, setShowDrawer] = useState(false);
	const currentPath = useCurrentPath();
	const [searchParams] = useSearchParams();
	const buildPath = (fullUrl) => {
		try {
			const lastPart = fullUrl.split("/").pop();
			if (lastPart.trim().length === 0) {
				return "home";
			}
			return lastPart.replaceAll("%20", " ");
		} catch (error) {
			return "/";
		}
	};
	useEffect(() => {
		const payLoad = {
			path: buildPath(window.location.href),
		};
		fetch(`${BASE_URL}/count`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payLoad),
		});
	}, [searchParams, currentPath]);
	return (
		<div>
			<Background />
			<NavigationBar
				isSideDrawerOpen={showDrawer}
				setShowSideDrawer={setShowDrawer}
			/>
			<Container fluid={currentPath === "/contact" ? true : false}>
				<Backdrop
					closeDrawer={() => {
						setShowDrawer(false);
					}}
					showBackdrop={showDrawer}
				/>
				<SlideDrawer
					showDrawer={showDrawer}
					setShowDrawer={setShowDrawer}
				/>
				<div style={{ height: "140px" }}></div>
				<Outlet />
			</Container>
			<Footer />
		</div>
	);
}

function Footer() {
	return (
		<div className="footer-wrapper">
			<div className="first-row">
				<div className="links-row">
					<FooterLink title={"Home"} link={"/"} />
					<FooterLink title={"Products"} link={"/products"} />
					<FooterLink title={"Services"} link={"/services"} />
					<FooterLink title={"Contact"} link={"/contact"} />
				</div>
				<div
					style={{
						width: "100%",
						textAlign: "center",
					}}>
					<div className="footer-brand">
						<img
							src={logo}
							alt="Sre Printograph Brand Logo"
							className="footer-img"
						/>
						<span className="footer-brand-name">
							SRE PRINTOGRAPH
						</span>
					</div>
				</div>
			</div>
			<div className="second-row">
				<p className="footer-address">
					1 / 202 / 3-E,
					<br /> Sakthi Nagar,
					<br /> Sivakasi West,
					<br />
					Tamil Nadu - 626 124.
				</p>
				<div className="developer-details">
					This site is Designed and Developed by Akil Prasath R<br />
					<a
						href="mailto:akilprasathr@gmail.com"
						className="footer-dev-email">
						akilprasathr@gmail.com
					</a>
				</div>
			</div>
		</div>
	);
}

function FooterLink({ title, link, ...props }) {
	const navigate = useNavigate();
	return (
		<div
			onClick={() => {
				navigate(link);
			}}
			href={link}
			className="footer-item">
			{title}
		</div>
	);
}
