import { matchRoutes, useLocation } from "react-router-dom";

const routes = [
	{ path: "/" },
	{ path: "/products" },
	{ path: "/services" },
	{ path: "/contact" },
];

export const useCurrentPath = () => {
	const location = useLocation();
	const [{ route }] = matchRoutes(routes, location);

	return route.path;
};
